import React, { useContext, useEffect } from "react";
import { LogoAnimationContext } from "../context/LogoAnimationContext";
import { InView } from "react-intersection-observer";

const InViewLogoAnimationController = ({ animationName, children }) => {
    const { setCurrentAnimationName } = useContext(LogoAnimationContext);

    useEffect(() => {
        // cleanup
        return () => {
            setCurrentAnimationName("default");
        };
    }, []);

    return (
        <InView
            as={"div"}
            threshold={0.9}
            onChange={(InView, entry) => {
                if (entry.isIntersecting) {
                    setCurrentAnimationName(animationName);
                }
            }}
            className="logo__animation-controller"
        >
            {children}
        </InView>
    );
};

export default InViewLogoAnimationController;
