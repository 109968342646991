import React from "react";
import Layout from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import ProjectGrid from "../components/ProjectGrid";
import ContactUsBlock from "../components/ContactUsBlock";
import useResolveRichText from "../hooks/useResolveRichText";
import Seo from "../components/seo";

export default function CaseStudyDetail({ data, location }) {
    const formattedHTML = useResolveRichText(data.PageData.caseStudyContent);
    const seoTitle = data.PageData.metaTitle ? data.PageData.metaTitle : data.PageData.name;
    const seoDescription = data.PageData.metaDescription
        ? data.PageData.metaDescription.metaDescription
        : data.PageData.description.description;
    const OgImage = data.PageData.ogImage?.gatsbyImageData?.images.fallback.src;
    const isAI = data.PageData.type === "AI";
    return (
        <Layout>
            <Seo
                image={OgImage}
                title={seoTitle}
                description={seoDescription}
                url={location.href}
            />
            <section className="case-study-hero">
                <div className="container container--first flex flex-ai-c">
                    <div className="hero-text">
                        <p className="section-tag gap-2x">Case study</p>
                        <div className="logo gap-1x">
                            {data.PageData?.logo?.gatsbyImageData ? (
                                <GatsbyImage
                                    image={data.PageData.logo.gatsbyImageData}
                                    alt={data.PageData.logo.title}
                                />
                            ) : (
                                <img
                                    src={data.PageData?.logo?.file.url}
                                    alt={data.PageData?.logo?.title}
                                />
                            )}
                        </div>
                        <h1 className="text-h2 text-fw-medium text-clr-primary">
                            {data.PageData.description.description}
                        </h1>
                    </div>
                </div>
            </section>
            <section className="case-study__overview">
                <div className="container container--py">
                    <div className="case-study__grid">
                        <div className="case-study__grid-col">
                            <div className="block-title">
                                <p className="case-study__grid-col__index">01</p>
                                <h2 className="case-study__grid-col__name">Overview</h2>
                            </div>
                        </div>
                        <div className="case-study__grid-col">
                            <div className="case-study__overview__content">
                                <p className="case-study__overview__title">Duration</p>
                                <p className="case-study__overview__list">
                                    {data.PageData.duration}
                                </p>
                            </div>
                            <div className="case-study__overview__content">
                                <p className="case-study__overview__title">Services</p>
                                <ul>
                                    {data.PageData.services &&
                                        data.PageData.services.map((s) => (
                                            <li key={s.id} className="case-study__overview__list">
                                                {s.title}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className="case-study__overview__content">
                                <p className="case-study__overview__title">Tech used</p>
                                <ul>
                                    {data.PageData.techUsed.map((t) => (
                                        <li key={t.id} className="case-study__overview__list">
                                            {t.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-studies__content-block">
                <div className="container container--py">{formattedHTML}</div>
            </section>
            <section>
                <div className="container container--last">
                    <ContactUsBlock aichapter={isAI} />
                </div>
            </section>
            <section className="case-studies-portfolio">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h2 text-fw-medium text-clr-primary gap-2x">
                            Explore more case studies
                        </h2>
                    </div>
                    <ProjectGrid CaseStudiesData={data.OtherCaseStudies.nodes} />
                </div>
            </section>
        </Layout>
    );
}

export const query = graphql`
    query CaseStudiesPageQuery($id: String, $relatedPages: [String]) {
        PageData: contentfulCasestudies(id: { eq: $id }) {
            id
            name
            slug
            logo {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
                file {
                    url
                }
            }
            ogImage {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            description {
                description
            }
            metaTitle
            metaDescription {
                metaDescription
            }
            duration
            services {
                id
                title
            }
            techUsed {
                id
                name
            }
            caseStudyContent {
                raw
                references {
                    file {
                        contentType
                        fileName
                        url
                    }
                    ... on ContentfulAsset {
                        __typename
                        contentful_id
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                }
            }
            type
        }
        OtherCaseStudies: allContentfulCasestudies(
            filter: { id: { in: $relatedPages }, type: { eq: "Non-AI" } }
            limit: 4
        ) {
            nodes {
                id
                name
                logo {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                    title
                }
                services {
                    id
                    title
                    description {
                        description
                    }
                }
                ogImage {
                    gatsbyImageData(sizes: "100", placeholder: BLURRED, layout: CONSTRAINED)
                }
                description {
                    description
                }
                doneBy {
                    name
                }
                backgroundType
                backgroundMedia {
                    gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                    title
                    file {
                        contentType
                        url
                    }
                }
                colorTheme
                thumbnailTheme
                slug
                type
            }
        }
    }
`;
