import React from "react";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lottie from "react-lottie";
import InViewLogoAnimationController from "../components/InViewLogoAnimationController";

const useResolveRichText = (rawHTML) => {
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children) => {
                if (data.uri.includes("youtube") || data.uri.includes("youtu.be")) {
                    let videoId = data.uri.substring(data.uri.lastIndexOf("/") + 1);
                    return (
                        <InViewLogoAnimationController animationName={"play"}>
                            <iframe
                                width="100%"
                                height="360"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                frameBorder="0"
                                title={`youtube embed ${videoId}`}
                            ></iframe>
                        </InViewLogoAnimationController>
                    );
                }
                return (
                    <a href={data.uri} target="_blank" rel="noreferrer">
                        {children}
                    </a>
                );
            },
            [BLOCKS.HEADING_1]: (node, children) => {
                return <span className="block-title">{children}</span>;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { gatsbyImageData, title, file } = node.data.target;

                // if (file.contentType === "video/mp4")
                //     return (
                //         <InViewLogoAnimationController animationName={"play"}>
                //             <video controls>
                //                 <source src={file.url} type={file.contentType} />
                //             </video>
                //         </InViewLogoAnimationController>
                //     );

                // if (file.contentType === "application/json")
                //     return (
                //         <div className="lottie-player-wrap">
                //             <Lottie
                //                 className="lottie-player"
                //                 options={{
                //                     loop: true,
                //                     autoplay: true,
                //                     path: file.url,
                //                     rendererSettings: {
                //                         preserveAspectRatio: "xMidYMid slice",
                //                     },
                //                 }}
                //             />
                //         </div>
                //     );

                // return <GatsbyImage image={getImage(gatsbyImageData)} alt={title} />;
                if (node.data.target) {
                    if (file?.contentType === "video/mp4")
                        return (
                            <InViewLogoAnimationController animationName={"play"}>
                                <video controls>
                                    <source src={file?.url} type={file?.contentType} />
                                </video>
                            </InViewLogoAnimationController>
                        );

                    if (file?.contentType === "application/json")
                        return (
                            <div className="lottie-player-wrap">
                                <Lottie
                                    className="lottie-player"
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        path: file?.url,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                        );

                    return <GatsbyImage image={getImage(gatsbyImageData)} alt={title} />;
                }
            },
        },
    };
    return buildLayout(renderRichText(rawHTML, options));
};

function buildLayout(elements) {
    try {
        const leftColContent = elements
            .filter((e) => e.type === "span" && e.props.className === "block-title")
            .map((e, i) => React.createElement("div", { className: "block-col left", key: i }, e));
        const rightColContent = [];
        let rightColChildren = [];
        elements.forEach((e, i) => {
            if (e.type !== "span" && e.props.className !== "block-title") {
                rightColChildren.push(e);
            } else {
                if (rightColChildren.length !== 0) {
                    rightColContent.push(
                        React.createElement(
                            "div",
                            { className: "block-col right", key: i },
                            rightColChildren
                        )
                    );
                    rightColChildren = [];
                }
            }
            // on last iteration push
            if (i === elements.length - 1) {
                rightColContent.push(
                    React.createElement(
                        "div",
                        { className: "block-col right", key: i },
                        rightColChildren
                    )
                );
                rightColChildren = [];
            }
        });
        if (leftColContent.length !== rightColContent.length) throw "layout mismatch";
        return leftColContent.map((lc, i) =>
            React.createElement("div", { className: "block-row", key: i }, [lc, rightColContent[i]])
        );
    } catch (error) {
        return error;
    }
}

export default useResolveRichText;
